import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormsModule,
  NgForm,
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import { ToasterService } from '@app/shared/services/toaster.service';
import { AuthService } from '@app/auth/services/auth-service.service';
import { notification_msg, country_list, error_msg, default_const } from "@app/shared/constants/consts";
import { Subscription } from 'rxjs';
import { TokenManager } from '@app/shared/services/token-manager.service';
import { default_const_flag } from '@app/shared/constants/country-flag-list';

export interface ForgetPasswordFormFields {
  email: any;
 
}
declare var $: any;
declare var geoplugin_countryCode: any;
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html'
})
export class ForgetPasswordComponent implements OnInit, OnDestroy {
 
  default_phone_number_country_code: any = default_const.Default_Country_Code;
  countryList = [];
  forgetPasswordResetForm: FormGroup;
  loading = false;
  errorMsg = error_msg;

  submitSubscription: Subscription;
  
  

  initData: ForgetPasswordFormFields = {
    email: '',
   
  };
  countryData: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toasterService: ToasterService,
    private auth: AuthService,
    private token: TokenManager,
  ) { }

  ngOnInit() {
    document.getElementsByTagName('body')[0].classList.add('loginpage');
    this.createForm();
  //   $('#phoneField').hide();
  //   $('#showPhoneField').click(function () {
  //     $('#phoneField').show();
  //     $('#emailField').hide();
   
  // })
  // $('#showEmailField').click(function () {
  //     $('#emailField').show();
  //     $('#phoneField').hide();
     
  // })
    
  }

  createForm() {
    this.forgetPasswordResetForm = this.fb.group({
      email: [
        this.initData.email,
        Validators.compose([
          Validators.required,
          Validators.pattern(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/),
          Validators.maxLength(100)
        ])
      ],
     
    });
  }

  
  getCountry(item) {
    this.countryData = item;
  }

  onSubmit() {
   

    if (this.forgetPasswordResetForm.valid) {
      this.loading = true;
      if (this.submitSubscription) {
        this.submitSubscription.unsubscribe();
      }
      const formVal = this.forgetPasswordResetForm.value;
      const formData = {};
       formData['type'] = 'email'
      for (const key in formVal) {
        if (formVal.hasOwnProperty(key)) {
          {
            formData[key] = formVal[key];
          }
        }
      }
      // console.log(formData)
      this.submitSubscription = this.auth.forgetPassword(formData).subscribe(
        (result: any) => {
          if (result.success === true) {
            this.loading = false;
            this.toasterService.Success(result.message);
            this.router.navigate(['/']);
          } else {
            this.toasterService.Error(result.message);
          }
          this.loading = false;
          this.submitSubscription.unsubscribe();
        },
        err => {
           this.toasterService.Error(err.error.message);
          this.loading = false;
          this.submitSubscription.unsubscribe();
        }
      );
    } else {
      this.toasterService.Error(notification_msg.FILL_ALL_DETAILS);
      Object.keys(this.forgetPasswordResetForm.controls).forEach(key => {
        const control = this.forgetPasswordResetForm.get(key);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

 
  ngOnDestroy() {
    document.getElementsByTagName('body')[0].classList.remove('loginpage');
  }

  

  


  get email() {
    return this.forgetPasswordResetForm.get('email');
  }

 
}
