import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { ExportCsvPdfService } from "@app/shared/component/export-csv-pdf/export-csv-pdf.service";

@Component({
  selector: "app-export-csv-pdf1",
  templateUrl: "./export-csv-pdf.component.html",
  providers: [ExportCsvPdfService]
})
export class ExportCsvPdf1Component implements OnInit {
  @Input() URL: any;
  @Input() Params: any;
  @Input() ExternalClass: any;
  queryString: any;
  type: any;
  constructor(private exportService: ExportCsvPdfService) {}

  ngOnInit() {
    // console.log(this.Params);
  }

  getCSV() {
    this.type = 'excel';
    const queryData = this.generateQueryString(this.type);
    // console.log(this.URL + "/" + this.type + queryData);
    this.exportService.getCSVFile(this.URL , queryData);
  }

  getPDF() {
    this.type = "pdf";
    let queryData = this.generateQueryString(this.type);
    // console.log(this.URL + "/" + this.type + queryData);
    this.exportService.getPDFFile(this.URL , queryData);
  }

  generateQueryString(type: string): string {
    if (this.Params) {
      if (this.Params.hasOwnProperty("type")) {
        // console.log('this.Params["type"]', this.Params["type"]);
      } else {
        // this.Params["type"] = type;
      }

      this.queryString = new URLSearchParams(this.Params);
      return this.queryString.toString();
    } else {
    }
  }
}
