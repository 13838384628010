<!-- <div id="preloader" *ngIf="loading">
    <div class="inner">
        <img src="assets/images/loading.gif" alt="loader">
    </div>
</div> -->

<!-- <div id="preloader" *ngIf="loading">
    <div class="inner">
        <div class="spinner">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
            <div class="rect5"></div>
        </div>
    </div>
</div> -->

<div class="page-loader" *ngIf="loadingFull">
  <div class="loader-inner">
      <div class="bigSqr">
          <div class="square first"></div>
          <div class="square second"></div>
          <div class="square third"></div>
          <div class="square fourth"></div>
      </div>
      <div class="text">loading</div>
  </div>
</div>
