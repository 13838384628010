<div class="login_sec_outer d-flex align-items-center bg-light"> 
  <div class="login_sec_inner m-auto">
     <div class="logo_sec text-center">
             <img src="assets/images/logo-blue.png" alt="logo">
     </div> 
     <div class="login_sec box-shadow">
         <div class="heading bg-theme">
           <div class="title color-white font-md text-center">FORGOT PASSWORD</div>
         </div>
         <div class="content bg-white">
             <form [formGroup]='forgetPasswordResetForm' class="needs-validation" autocomplete="off" novalidate  [ngClass]="{'was-validated': forgetPasswordResetForm.touched && !forgetPasswordResetForm.valid}" >
                 <div class="form-group" id='emailField'>
                     <input class="form-element-field" appInputFocus formControlName="email" type="text" required (change)="email.setValue($event.target.value.trim())">
                      <label class="form-element-label">Email address*</label>
                     <div *ngIf="email.touched && !email.valid && email.errors?.required" class="error">{{errorMsg.EMAIL_REQUIRED}}</div>
                     <div *ngIf="email.dirty && email.errors?.maxlength" class="error">{{errorMsg.EMAIL_MAX_LENGTH(100)}}</div>
                     <div *ngIf="email.dirty && email.errors?.pattern" class="error">{{errorMsg.EMAIL_INCORRECT_FORMAT}}</div>
                     <a class="changeInput" id="showPhoneField" [routerLink]="['/forgot-password-phone']">Use Phone Number</a>
                 </div> 

               
                 <app-data-loader [loading]="loading"> </app-data-loader>
                 <div class="btn-row d-flex align-items-center">
                     <button type="submit"  (click)="onSubmit()" class="btn btn-primary">Submit  </button>
                     <a class="ml-auto" [routerLink]="['/login']">Back to Login</a>
                 </div>
             </form>
         </div>
     </div>
 </div>
     </div>  