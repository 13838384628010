<div class="login_sec_outer d-flex align-items-center bg-light">
  <div class="login_sec_inner m-auto">
    <div class="logo_sec text-center">
      <img src="assets/images/logo-blue.png" alt="logo">
    </div>
    <div class="login_sec box-shadow">
      <div class="heading bg-theme">
        <div class="title color-white font-md text-center">ADMIN LOGIN</div>
      </div>
      <div class="content bg-white">

        <form name="form" [formGroup]="frm" class="needs-validation" [ngClass]="{'was-validated': frm.touched && !frm.valid}"
              novalidate autocomplete="off">
          <div class="form-group">

            <input appInputFocus formControlName="username" (change)="username.setValue($event.target.value.trim())"
                   type="text" class="form-element-field" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$" name='username'
                   required>
            <label class="form-element-label">Email*</label>

            <div class="error" *ngIf="username.touched && !username.valid && username.errors?.required">
              Please enter email.</div>
            <div class="error" *ngIf="username.dirty && username.errors?.pattern"> Email should be in valid
              format.</div>
          </div>
          <div class="form-group">

            <input appInputFocus (change)="password.setValue($event.target.value.trim())" formControlName="password"
                   type="password" class="form-element-field" name='password' required>

            <label class="form-element-label">Password*</label>

            <div class="error" *ngIf="password.touched && !username.valid && password.errors?.required">
              Please enter password.
            </div>
          </div>
          <div class="btn-row d-flex align-items-center">
            <!-- <button type="submit" class="btn btn-primary">Login <i class="fa fa-spin fa-spinner" style="display:none"></i></button> -->

            <button type="submit" class="btn btn-primary" [disabled]=loading (click)="login()">Login
              <!-- <app-data-loader class="loader" [loading]='loading'></app-data-loader> -->
              <i class="fa fa-spin fa-spinner" *ngIf='loading'></i>
            </button>

            <a class="ml-auto" href="javascript:void(0);" [routerLink]="['/forgot-password']">Forgot password?
              <!-- <span class="ti-arrow-right"></span> -->
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
