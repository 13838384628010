import { Injectable } from "@angular/core";
import { ApiHandler } from "@app/shared/services/api-handler.service";
import { TokenManager } from "@app/shared/services/token-manager.service";
import { ToasterService } from "@app/shared/services/toaster.service";
import { notification_msg } from "@app/shared/constants/consts";

@Injectable()
export class ExportCsvPdfService {
  constructor(
    private apiHandler: ApiHandler,
    private tokenManager: TokenManager,
    public toasterService: ToasterService
  ) {}

  getFile(url, queryParam) {
    let reqUrl: string;
    if (queryParam) {
      reqUrl = `${url}?access_token=${this.tokenManager.fetchToken()}&${queryParam}`;
    } else {
      reqUrl = `${url}?access_token=${this.tokenManager.fetchToken()}`;
    }

    window.open(reqUrl);
  }

  getCSVFile(url, queryParam) {
    let reqUrl: string;
    if (queryParam) {
      reqUrl = `${url}?access_token=${this.tokenManager.fetchToken()}&file_type=excel&${queryParam}`;
    } else {
      reqUrl = `${url}?access_token=${this.tokenManager.fetchToken()}&file_type=excel`;
    }

    window.open(reqUrl);
  }

  getPDFFile(url, queryParam) {
    let reqUrl: string;
    if (queryParam) {
      reqUrl = `${url}?access_token=${this.tokenManager.fetchToken()}&${queryParam}`;
    } else {
      reqUrl = `${url}?access_token=${this.tokenManager.fetchToken()}`;
    }

    this.apiHandler.apiGet(reqUrl).subscribe({
      next: (result: any) => {
        if (result.data) {
          const data = result.data["filepath"];
           window.open(data);
        }
      },
      error: err => {
        this.toasterService.Error(notification_msg.SERVER_NOT_RESPONDING);
      },
      complete: () => {}
    });

    // window.open(reqUrl);
  }
}
