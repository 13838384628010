
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Headers, Http, RequestOptions, Response } from "@angular/http";
import "rxjs/Rx";
import { Observable } from "rxjs";
import { TokenManager } from "@app/shared/services/token-manager.service";
import { ApiHandler } from "@app/shared/services/api-handler.service";
import { API } from "environments/environment";
import { HttpClient } from '@angular/common/http';
import { default_const } from '@app/shared/constants/consts';

@Injectable()
export class AuthService {
  redirectUrl: string;
  ipAddress: any;

  constructor(
    private apiHandler: ApiHandler,
    private manageToken: TokenManager,
    private http: HttpClient
  ) {}

  isLoggedIn() {
    // TODO: need to add logic to invalidate token
    if (this.manageToken.fetchToken()) {
      return true;
    }
  }
  getGeoLocationData() {
    return this.http.get(API.GEO_LOCATION_PLUGIN_URL,{responseType:"json"});
  }
  loginUser(userName: string, password: string): Observable<any> {
    // Frame JSON body
    const obj_geo_plugin_data = this.manageToken.fetchDataByKey(default_const.GEO_PLUGIN_DATA);
    this.ipAddress = obj_geo_plugin_data["geoplugin_request"];
    
    const loginReqBody = {
      email: userName,
      password: password,
      device_type: "web",
      device_id: "",
      environment: "dev",
      ip:this.ipAddress,
      login_type: "email"
      
    };

    return this.apiHandler
      .apiPost(API.AUTH_ENDPOINTS.LOG_IN, loginReqBody).pipe(
      map((response: any) => {
        const data = response.data;
      //  console.log('data', data);
        const token = data.user_token.access_token;
        if (token) {
          localStorage.setItem(
            "currentUser",
            JSON.stringify({
              user_type: data.user_type,
              profile_image: data.user_profile.profile_image,
              token: token,
              name: data.name,
              id: data.id
              // photo: data.photo
            })
          );
        }
        return response;
      }));
  }

  forgetPassword(formData) {
    return this.apiHandler.apiPost(
      API.AUTH_ENDPOINTS.FORGET_PASSWORD,
      formData
    );
  }
  changeEmail(formData) {
    return this.apiHandler.apiPost(
      API.AUTH_ENDPOINTS.CHANGE_EMAIL,
      formData
    );
  }
  changeForgetMobile(formData) {
    return this.apiHandler.apiPost(
      API.AUTH_ENDPOINTS.CHANGE_PHONE_NUMBER,
      formData
    );
  }
  resetPassword(formData) {
    return this.apiHandler.apiPost(API.AUTH_ENDPOINTS.RESET_PASSWORD, formData);
  }

  changePassword(formData: any) {
    return this.apiHandler.apiPost(
      API.AUTH_ENDPOINTS.CHANGE_PASSWORD,
      formData
    );
  }

  logout() {
    const logoutReqBody = {access_token : this.manageToken.fetchToken()};
    return this.apiHandler.apiPost(API.AUTH_ENDPOINTS.LOGOUT, logoutReqBody);
  }
}
