// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true
};

export const encryption_key = "x90dh!2$bs";

let base_url = "";
base_url = "https://api.ozayed.com/api"; // Live URL 

class Endpoints {
  AUTH_BASE = base_url + "/auth";
  DASHBOARD_BASE = base_url + "/admin/dashboard";
  MODEL_BASE = base_url + "/admin/model";
  REFERAL_SYSTEM_BASE = base_url + "/admin";
  MISCELLANEOUS_BASE = base_url + "";
  GEO_LOCATION_PLUGIN_URL =
    "https://ssl.geoplugin.net/json.gp?k=1fdd7f41457ec628";

  DASHBOARD_ENDPOINTS = {
    GET_HEADER_DETAILS: this.DASHBOARD_BASE,
    GET_DASHBOARD_DETAIL: this.DASHBOARD_BASE,
    GET_INCOME_DISTRIBUTION: this.joinPaths(
      this.DASHBOARD_BASE,
      "income-distribution"
    ),
    GET_USER_GRAPH: this.joinPaths(this.DASHBOARD_BASE, "user-graph"),
    GET_DASHBOARD_CHART: this.joinPaths(base_url, "dashboard-chart"),
    GET_DASHBOARD_MEMBERSHIP_PLAN_SUMMARY: this.joinPaths(
      base_url,
      "membership-plan-summary"
    )
  };

  AUTH_ENDPOINTS = {
    LOG_IN: this.joinPaths(this.AUTH_BASE, "admin-login"),
    FORGET_PASSWORD: this.joinPaths(this.AUTH_BASE, "forgot-admin-password"),
    CHANGE_EMAIL: this.joinPaths(this.AUTH_BASE, "change-email"),
    CHANGE_PHONE_NUMBER: this.joinPaths(this.AUTH_BASE, "change-phone-number"),
    RESET_PASSWORD: this.joinPaths(this.AUTH_BASE, "reset-admin-password"),
    CHANGE_PASSWORD: this.joinPaths(this.AUTH_BASE, "change-password"),
    LOGOUT: this.joinPaths(this.AUTH_BASE, "logout")
  };
  ACCOUNT_ENDPOINTS = {
    GET_ACCOUNT_DETAIL: base_url + "/user-account",
    ADD_ACCOUNT_DETAIL: base_url + "/user-account",
    GET_ADMIN_PAYMENT_METHOD_LIST: base_url + "/admin/payment-method/list",
    UPDATE_ADMIN_PAYMENT_METHOD_LIST: base_url + "/admin/payment-method/update",
  };
  USER_ENDPOINTS = {
    GET_USER_LIST: base_url + "/user",
    GET_USER_DETAIL: (id: any) => this.joinPaths(base_url + "/user", id),
    POST_USER: this.joinPaths(base_url + "/user"),
    UPLOAD_PROFILE_IMAGE_USER: (id: any) =>
      this.joinPaths(base_url + "/user", id, "upload-profile-image"),
    UPDATE_USER: (id: any) => this.joinPaths(base_url + "/user", id),
    USER_STATUS_UPDATE_URL: (id: any) =>
      this.joinPaths(base_url + "/user", id, "change-status"),
    GET_STATE_LIST: (id: any) => this.joinPaths(base_url + "/state", id),
    GET_COUNTRY_LIST: base_url + "/country"
  };

  ADMIN_ENDPOINTS = {
    GET_ADMIN_LIST: base_url + "/admin",
    ADD_ADMIN: base_url + "/admin",
    GET_COUNTRY_LIST: base_url + "/country",
    GET_STATE_LIST: (id: string) => this.joinPaths(base_url + "/states/" + id),
    GET_CITY_LIST: (id: string) => this.joinPaths(base_url + "/cities/" + id),
    GET_ADMIN_DETAIL: (id: any) => this.joinPaths(base_url + "/admin", id),
    UPDATE_ADMIN: (id: any) => this.joinPaths(base_url + "/admin", id),
    ADMIN_STATUS_UPDATE_URL: (id: any) =>
      this.joinPaths(base_url + "/admin", id, "status"),

    GET_COMMISSION_REPORT_CUSTOMER_LIST: this.joinPaths(
      base_url + "/admin/report/get-customer-list"
    ),
    GET_COMMISSION_REPORT_CUSTOMER_UNPAID_LIST: this.joinPaths(
      base_url + "/admin/report/get-unpaid-customer-list"
    ),
    GET_COMMISSION_REPORT_CUSTOMER_PAYMENT_LIST: (customerId: any) =>
      this.joinPaths(
        base_url + "/admin/report/get-report-by-customer",
        customerId
      ),
    GET_COMMISSION_REPORT_CUSTOMER_BOOKING_LIST: (customerId: any) =>
      this.joinPaths(base_url + "/admin/report/get-report-by-month", customerId)
    // GET_COMMISSION_REPORT_CUSTOMER_LIST: id => this.joinPaths(base_url + "/admin/report/get-customer-list"),
  };

  PROPERTY_ENDPOINTS = {
    GET_PROPERTY_LIST: base_url + "/property",
    GET_PROPERTY_DOWNLOAD_LIST: base_url + "/property/download",
    PROPERTY_STATUS_UPDATE_URL: (id: any) =>
      this.joinPaths(base_url + "/property", id, "change-status"),
    GET_FACILITY_LIST: base_url + "/facility-list",
    GET_CANCELLATION_RULES_DAYS_LIST:
      base_url + "/property/cancellation-rule-days",
    GET_CANCELLATION_RULES_PECENTAGE_LIST:
      base_url + "/property/cancellation-rule-percentage",
    PROPERTY_TYPE_LIST: base_url + "/property-type-list",
    ADD_PROPERTY: base_url + "/property/description",
    UPDATE_PROPERTY: (id: string) =>
      this.joinPaths(base_url + "/property/" + id + "/description"),
    UPDATE_PROPERTY_RULE: (id: string) =>
      this.joinPaths(base_url + "/property/" + id + "/rule"),
    GET_PROPERTY: (id: any) =>
      this.joinPaths(base_url + "/property", id, "detail"),
    ADD_PROPERTY_IMAGE: (id: string) =>
      this.joinPaths(base_url + "/property/" + id + "/upload-image"),
    UPDATE_DEFAULT_IMAGE: (id: string, imageId: string) =>
      this.joinPaths(
        base_url + "/property/" + id + "/set-default-image/" + imageId
      ),
    DELETE_PROPERTY_IMAGE: (id: string, imageId: string) =>
      this.joinPaths(base_url + "/property/" + id + "/image/" + imageId),
    GET_CURRENCY_LIST: base_url + "/currency",
    PROPERTY_PRICE: (id: string) =>
      this.joinPaths(base_url + "/property/" + id + "/price"),
    MY_PROPERTY: base_url + "/my-property?status=draft",
    MY_PROPERTY_INPROGRESS: base_url + "/my-property?status=draft",
    MY_PROPERTY_PENDING: base_url + "/my-property?status=pending",
    MY_PROPERTY_APPROVED: base_url + "/my-property?status=approved",
    GET_PROPERTY_DETAIL: (code: any) =>
      this.joinPaths(base_url + "/property-detail", code),
    GET_PROPERTY_REVIEW: (id: string) =>
      this.joinPaths(base_url + "/property", id + "/rating-reviews"),
    GET_PROPERTY_SPECIAL_RATING: (id: string) =>
      this.joinPaths(base_url + "/property", id + "/special-ratings"),
    ADD_REMOVE_WISHLIST: (id: string) =>
      this.joinPaths(base_url + "/property/" + id + "/add-to-wishlist/"),
    PROPERTY_OFFER: (id: string) =>
      this.joinPaths(base_url + "/property/" + id + "/offer"),
    DELETE_PROPERTY: (id: any) => this.joinPaths(base_url + "/property", id),

    GET_EDIT_PROPERTY: (id: any) =>
      this.joinPaths(base_url + "/property", id, "edit-request"),

    POST_EDIT_PROPERTY: (id: any) =>
      this.joinPaths(base_url + "/property", id, "edit-request"),
  };

  BOOKINGS_ENDPOINTS = {
    GET_BOOKINGS_LIST: base_url + "/admin/bookings",
    GET_BOOKINGS_DETAIL: (id: any) =>
      this.joinPaths(base_url + "/admin/bookings", id)
  };

  PAYMENTS_ENDPOINTS = {
    GET_PAYMENTS_LIST: base_url + "/payments",
    GET_PAYMENT_DETAIL: (id: any) => this.joinPaths(base_url + "/payments", id),
    UPDATE_PAYMENT: (id: any) => this.joinPaths(base_url + "/payments", id)
  };

  MEMBERSHUP_PLAN = {
    GET_MEMBERSHIP_PLAN_LIST: base_url + "/membership-plan",
    ADD_MEMBERSHIP_PLAN: base_url + "/membership-plan",
    GET_MEMBERSHIP_PLAN_DETAILS: (id: any) =>
      this.joinPaths(base_url + "/membership-plan", id),
    UPDATE_MEMBERSHIP_PLAN: (id: any) =>
      this.joinPaths(base_url + "/membership-plan", id),
    UPDATE_MEMBERSHIP_PLAN_STATUS: (id: any) =>
      this.joinPaths(base_url + "/membership-plan", id, "change-status"),
    DELETE_MEMBERSHIP_PLAN: (id: any) =>
      this.joinPaths(base_url + "/membership-plan", id)
  };

  REVIEWS_RATING = {
    GET_REVIEWS_RATING_LIST: base_url + "/admin/property-rating-review",
    GET_REVIEWS_RATING_DETAILS: (id: any) =>
      this.joinPaths(base_url + "/admin/property-rating-review", id),
    UPDATE_MEMBERSHIP_PLAN: (id: any) =>
      this.joinPaths(base_url + "/membership-plan", id),
    UPDATE_MEMBERSHIP_PLAN_STATUS: (id: any) =>
      this.joinPaths(base_url + "/membership-plan", id, "change-status"),
    DELETE_MEMBERSHIP_PLAN: (id: any) =>
      this.joinPaths(base_url + "/membership-plan", id)
  };
  COMMISSION_ENDPOINTS = {
    GET_COMMISSIONS_LIST: base_url + "/commission",
    ADD_COMMISSION: base_url + "/commission",
    GET_COMMISSION_DETAILS: (id: any) =>
      this.joinPaths(base_url + "/commission", id),
    UPDATE_COMMISSION: (id: any) =>
      this.joinPaths(base_url + "/commission", id),
    UPDATE_COMMISSION_STATUS: (id: any) =>
      this.joinPaths(base_url + "/commission", id, "change-status"),
    DELETE_COMMISSION: (id: any) => this.joinPaths(base_url + "/commission", id)
  };

  PROPERTY_TYPE_ENDPOINTS = {
    GET_PROPERTY_TYPES: base_url + "/property-type",
    ADD_PROPERTY_TYPES: base_url + "/property-type",
    GET_PROPERTY_TYPE_DETAIL: (id: any) =>
      this.joinPaths(base_url + "/property-type", id),
    UPDATE_PROPERTY_TYPE: (id: any) =>
      this.joinPaths(base_url + "/property-type", id),
    UPDATE_PROPERTY_TYPE_STATUS: (id: any) =>
      this.joinPaths(base_url + "/property-type", id, "change-status"),
    DELETE_PROPERTY_TYPE: (id: any) =>
      this.joinPaths(base_url + "/property-type", id, "delete")
  };

  SETTING_ENDPOINTS = {
    GET_SETTING_LIST: base_url + "/setting",
    UPDATE_SETTING: (id: any) => this.joinPaths(base_url + "/setting", id),
    UPDATE_SETTING_STATUS: base_url + "/setting/change-status"
  };

  CONTACT_US_ENDPOINTS = {
    GET_SETTING_LIST: base_url + "/contact-inquiry",
    DELETE_SETTING_LIST: (id: any) =>
      this.joinPaths(base_url + "/contact-inquiry", id),
    GET_CONTACT_INQUIRY_LIST_DATA: base_url + "/contact-inquiry-type-list"
  };

  private joinPaths(...params: string[]) {
    const newUrl = params.join("/");
    return newUrl;
  }

  private joinPathsWithQueryParams(...params: any[]) {
    const newUrl = params.join("?");
    return newUrl;
  }
}

export const API = new Endpoints();
