import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appOnFocus]'
})

export class OnFocusDirective {

    @Input() focusClasses: Array<string>;

    constructor(private ele: ElementRef) {}

    @HostListener('focus') onElementFocus() {
        this.focusClasses.map((className) => {
            this.ele.nativeElement.parentElement.classList.add(className);
        });
    }

    @HostListener('blur') onElementBlur() {
        this.focusClasses.map((className) => {
            this.ele.nativeElement.parentElement.classList.remove(this.focusClasses);
        });
    }

}
