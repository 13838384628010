import { Subscription } from 'rxjs';
import { ToasterService } from './../../shared/services/toaster.service';
import { AuthService } from './../services/auth-service.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import { error_msg, notification_msg } from '@app/shared/constants/consts';

export interface ResetPasswordFormFields {
  password: any;
  confirm_password: any;
}
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup;
  loading: boolean;
  token: string;
  errorMsg = error_msg;

  initData: ResetPasswordFormFields = {
    password: '',
    confirm_password: ''
  };

  private subscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private auth: AuthService,
    private toasterService: ToasterService
  ) {
     this.token = this.activatedRoute.snapshot.params['token'];
    if (!this.token) {
      this.subscription = activatedRoute.queryParams.subscribe(
        (param: any) => (this.token = param['token'])
      );
    }
  }

  ngOnInit() {
    document.getElementsByTagName('body')[0].classList.add('loginpage');
    this.createForm();
  }

  createForm() {
    this.resetForm = this.fb.group(
      {
        password: [
          this.initData.password,
          Validators.compose([
            Validators.required,
            // Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,20}$/),
            Validators.pattern(/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/),
            // Validators.minLength(8),
            Validators.maxLength(15)
        ])
        ],
        confirm_password: [
          this.initData.confirm_password,
          Validators.compose([
            Validators.required,
            // Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,20}$/),
            Validators.pattern(/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/),
            // Validators.minLength(8),
            Validators.maxLength(15)
        ])
        ]
      },
      {
        validator: this.MatchPassword.bind(this)
      }
    );
  }

  resetPassword() {
    this.loading = true;
    if (this.resetForm.valid) {

      if ( this.subscription ) {
        this.subscription .unsubscribe();
      }

      const formVal = this.resetForm.value;
      const formData = {};
      for (const key in formVal) {
        if (formVal.hasOwnProperty(key)) {
          {
            if (key === 'confirm_password') {
              continue;
            }
            if (key === 'password') {
              formData['password'] = formVal[key];
              continue;
            }
          }
        }
      }
      formData['token'] = this.token;
      this.subscription  = this.auth.resetPassword(formData).subscribe(
        (result: any) => {
          if (result.success === true) {
            this.toasterService.Success(
              notification_msg.PASSWORD_UPDATE_SUCCESS
            );
            this.router.navigate(['/']);
          } else {
            this.toasterService.Error(result.message);
          }
          this.loading = false;
          this.subscription.unsubscribe();
        },
        err => {
          this.toasterService.Error(err.error.message);
          this.loading = false;
          this.subscription.unsubscribe();
        }
      );
    } else {
      this.toasterService.Error(notification_msg.FILL_ALL_DETAILS);
      this.resetForm.markAsTouched({ onlySelf: true });
      Object.keys(this.resetForm.controls).forEach(key => {
        const control = this.resetForm.get(key);
        control.markAsTouched({ onlySelf: true });
      });
      this.loading = false;
    }
  }

  MatchPassword(control: AbstractControl): ValidationErrors | null {
    const pass = control.get('password').value;
    const confirmedPass = control.get('confirm_password').value;
    if (pass !== confirmedPass) {
      return { passError: { msg: 'Password don\'t match' } };
    } else {
      return null;
    }
  }

  get password() {
    return this.resetForm.get('password');
  }

  get confirm_password() {
    return this.resetForm.get('confirm_password');
  }
}
