<!-- <div class="action-btn" [ngClass]="this.ExternalClass">
  Export via :
  <div class="d-inline-block addbtndiv">
    <a class="btn btn-warning" href="javascript:void(0);" (click)="getCSV()">
      <i class="fa fa-file-excel-o" aria-hidden="true"></i> CSV</a>
  </div>

  <div class="d-inline-block addbtndiv">
    <a class="btn btn-warning" href="javascript:void(0);" (click)="getPDF()">
      <i class="fa fa-file-pdf-o" aria-hidden="true"></i> PDF</a>
  </div>
</div> -->


<li class="list-inline-item">
  <a href="javascript:void(0);" (click)="getCSV()" class="nav-link filter_btn">
    <i class="fa fa-download"></i>
  </a>
</li>