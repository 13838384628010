<div class="login_sec_outer d-flex align-items-center bg-light">
  <div class="login_sec_inner m-auto">
    <div class="logo_sec text-center">
      <img src="assets/images/logo-blue.png" alt="logo">
    </div>
    <div class="login_sec box-shadow">
      <div class="heading bg-theme">
        <div class="title color-white font-md text-center">Reset</div>
      </div>
      <div class="content bg-white">

        <form name="form" [formGroup]="resetForm" class="needs-validation" [ngClass]="{'was-validated': resetForm.touched && !resetForm.valid}"
          novalidate autocomplete="off">
          <div class="form-group">

            <input appInputFocus formControlName="password" 
              type="password" class="form-element-field" required>
            <label class="form-element-label">Password*</label>

            <div *ngIf="password.touched && !password.valid && password.errors?.required" class="error">{{errorMsg.NEW_PASSWORD_REQUIRED}}</div>
            <div *ngIf="password.dirty && password.errors?.pattern" class="error">{{errorMsg.PASSWORD_INCORRECT_FORMAT}}</div>
            <!-- <div *ngIf="password.dirty && password.errors?.minlength" class="error">{{errorMsg.PASSWORD_MIN_LENGTH(8)}}</div> -->
            <div *ngIf="password.dirty && password.errors?.maxlength" class="error">{{errorMsg.PASSWORD_MAX_LENGTH(12)}}</div>
            
          </div>
          <div class="form-group">

            <input appInputFocus (change)="confirm_password.setValue($event.target.value.trim())" formControlName="confirm_password"
              type="password" class="form-element-field" name='password' required>

            <label class="form-element-label">Confirm Password*</label>
            <div *ngIf="confirm_password.touched && !confirm_password.valid && confirm_password.errors?.required" class="error">{{errorMsg.CONFIRM_PASSWORD_REQUIRED}}</div>
            <div *ngIf="confirm_password.dirty && confirm_password.errors?.pattern" class="error">{{errorMsg.PASSWORD_INCORRECT_FORMAT}}</div>
            <!-- <div *ngIf="confirm_password.dirty && confirm_password.errors?.minlength" class="error">{{errorMsg.PASSWORD_MIN_LENGTH(8)}}</div> -->
            <div *ngIf="confirm_password.dirty && confirm_password.errors?.maxlength" class="error">{{errorMsg.PASSWORD_MAX_LENGTH(12)}}</div>
           
            <div class="error" *ngIf="resetForm.hasError('passError')">
              {{resetForm.getError('passError').msg}}
            </div>
          </div>
          <div class="btn-row d-flex align-items-center">
            <!-- <button type="submit" class="btn btn-primary">Login <i class="fa fa-spin fa-spinner" style="display:none"></i></button> -->

            <button type="submit" class="btn btn-primary" (click)="resetPassword()">Submit
              <!-- <app-data-loader class="loader" [loading]='loading'></app-data-loader> -->
              <i class="fa fa-spin fa-spinner" *ngIf='loading'></i>
            </button>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>