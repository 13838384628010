<div class="login_sec_outer d-flex align-items-center bg-light"> 
  <div class="login_sec_inner m-auto">
     <div class="logo_sec text-center">
             <img src="assets/images/logo-blue.png" alt="logo">
     </div> 
     <div class="login_sec box-shadow">
         <div class="heading bg-theme">
           <div class="title color-white font-md text-center">FORGOT PASSWORD</div>
         </div>
         <div class="content bg-white">
             <form [formGroup]='forgetPasswordResetForm' class="needs-validation" autocomplete="off" novalidate  [ngClass]="{'was-validated': forgetPasswordResetForm.touched && !forgetPasswordResetForm.valid}" >
                 <!-- <div class="form-group" id='emailField'>
                     <input class="form-element-field" appInputFocus formControlName="email" type="text" required (change)="email.setValue($event.target.value.trim())">
                      <label class="form-element-label">Email address*</label>
                     <div *ngIf="email.touched && !email.valid && email.errors?.required" class="error">{{errorMsg.EMAIL_REQUIRED}}</div>
                     <div *ngIf="email.dirty && email.errors?.maxlength" class="error">{{errorMsg.EMAIL_MAX_LENGTH(100)}}</div>
                     <div *ngIf="email.dirty && email.errors?.pattern" class="error">{{errorMsg.EMAIL_INCORRECT_FORMAT}}</div>
                     <a class="changeInput" id="showPhoneField" href="javascript:;">Use Phone Number</a>
                 </div>  -->

                 <div class="form-group" id='phoneField'>
                    <div class="row">
                        <div class="col-sm-12">
                      <div class="row mobile-number">
                        <div class="col flag-dropdown pr-0 left-field">
                          <div class="form-group mb-0">
                            <ng-select class="custom-ngselect" #slct [searchable]="true" [clearable]="false"
                                       [(ngModel)]="default_phone_number_country_code" placeholder="Select Country Code"
                                       [items]="countryList" formControlName="phone_number_country_code" bindValue="callingCode"
                                       (change)="getCountry($event)" virtualScroll="true" bindLabel="callingCode">
                              <ng-template ng-label-tmp let-item="item" let-index="index" let-search="searchTerm">
                                <img [src]="item?.flag" [title]="item?.callingCode" width="25px" alt="" /> + {{ item?.callingCode }}
                              </ng-template>
                              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                <img [src]="item?.flag" [title]="item?.callingCode" width="25px" alt="" /> + {{ item?.callingCode }}
                              </ng-template>
                            </ng-select>
              
                          
              
                            <span *ngIf="phone_number_country_code.touched && !phone_number_country_code.valid && phone_number_country_code.errors.required"
                                  class="text-danger error">
                             {{errorMsg.CELL_NUMBER_CODE_REQUIRED}}
                            </span>
                          </div>
                        </div>
                        <div class="col pl-0 right-field">
                          <div class="form-group mb-0">
                            <input appInputFocus class="form-element-field" formControlName="phone_number" id="number" myNumberOnly
                                   name="phone" type="text" required />
                            <label class="form-element-label">  Mobile Number </label>
                           
                            <span *ngIf="phone_number.touched && !phone_number.valid && phone_number.errors.required"
                                  class="text-danger error">{{errorMsg.CELL_NUMBER_REQUIRED}} </span>
                            <span *ngIf="phone_number.dirty && phone_number.errors?.minlength"
                                  class="text-danger error"> {{errorMsg.CELL_NUMBER_MIN_LENGTH(6) }} </span>
                            <span *ngIf="phone_number.dirty && phone_number.errors?.maxlength"
                                  class="text-danger error">{{errorMsg.CELL_NUMBER_MAX_LENGTH(6) }}</span>
                            <span *ngIf="phone_number.dirty && phone_number.errors?.pattern"
                                  class="text-danger error">{{errorMsg.CELL_NUMBER_PATTERN }}</span>
                          </div>
                        </div>
                      </div>
                      </div>
                      </div>
                  
                    <a class="changeInput" id="showEmailField" [routerLink]="['/forgot-password']">Use Email</a>
                </div>
                <app-data-loader [loading]="loading"> </app-data-loader>
                 <div class="btn-row d-flex align-items-center">
                     <button type="submit"  (click)="loginStepI()" class="btn btn-primary">Submit </button>
                     <a class="ml-auto" [routerLink]="['/login']">Back to Login</a>
                 </div>
             </form>
         </div>
     </div>
 </div>
     </div>  