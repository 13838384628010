import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormsModule,
  NgForm,
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import { ToasterService } from '@app/shared/services/toaster.service';
import { AuthService } from '@app/auth/services/auth-service.service';
import { notification_msg, country_list, error_msg, default_const } from "@app/shared/constants/consts";
import { Subscription } from 'rxjs';
import { TokenManager } from '@app/shared/services/token-manager.service';
import { default_const_flag } from '@app/shared/constants/country-flag-list';

export interface ForgetPasswordFormFields {
 
  phone_number_country_code: any;
  phone_number_country: any;
  phone_number: any;
}
declare var $: any;
declare var geoplugin_countryCode: any;
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-phone.component.html'
})
export class ForgetPhoneComponent implements OnInit, OnDestroy {
 
  default_phone_number_country_code: any = default_const.Default_Country_Code;
  countryList = [];
  forgetPasswordResetForm: FormGroup;
  loading = false;
  errorMsg = error_msg;

  submitSubscription: Subscription;
  
  

  initData: ForgetPasswordFormFields = {
    
    phone_number_country_code: this.default_phone_number_country_code,
    phone_number: "",
    phone_number_country: ""
  };
  countryData: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toasterService: ToasterService,
    private auth: AuthService,
    private token: TokenManager,
  ) { }

  ngOnInit() {
    document.getElementsByTagName('body')[0].classList.add('loginpage');
    this.createForm();
    
    
  }

  createForm() {
    this.forgetPasswordResetForm = this.fb.group({
    
      phone_number_country_code: [this.initData.phone_number_country_code, Validators.compose([Validators.required])],
      phone_number: [this.initData.phone_number, Validators.compose([Validators.required, Validators.maxLength(12), Validators.minLength(6), Validators.pattern(/^[0-9]*$/)])],
      phone_number_country: [this.initData.phone_number_country]
    });
  }

  
  getCountry(item) {
    this.countryData = item;
  }

  loginStepI() {
    
    const phone_number_country = this.countryData ? this.countryData.key : default_const.Default_Country_Key;
    const formData = {
      phone_number_country_code: `+${this.forgetPasswordResetForm.value.phone_number_country_code}`,
      phone_number: this.forgetPasswordResetForm.value.phone_number.replace(/^0+/, ""),
      phone_number_country: phone_number_country,
      
    
    };
    formData['type'] = 'mobile'
    // this.phone_number.setValue(formData.phone_number);
    if (this.forgetPasswordResetForm.valid) {
      this.loading = true;
      this.submitSubscription = this.auth.forgetPassword(formData).subscribe(
        (result: any) => {
          if (result.success === true) {
            this.loading = false;
            this.toasterService.Success(result.message);
            this.router.navigate(['/admin/change-email']);
            // $("#reqOtpModal").modal("hide");
           
          } else {
            this.toasterService.Error(result.message);
          }
          this.loading = false;
          this.submitSubscription.unsubscribe();
        },
        err => {
           this.toasterService.Error(err.error.message);
          this.loading = false;
          this.submitSubscription.unsubscribe();
        }
      );
    } else {
      // if form is not valid
      this.loading = false;
      this.forgetPasswordResetForm.markAsDirty({ onlySelf: true });
      Object.keys(this.forgetPasswordResetForm.controls).forEach(field => {
        const control = this.forgetPasswordResetForm.get(field);
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });
      });
      this.toasterService.Error(notification_msg.FILL_ALL_DETAILS);
    }
  }
  

  ngOnDestroy() {
    document.getElementsByTagName('body')[0].classList.remove('loginpage');
  }

  async geoplugin_countryCodeData() {
  
      if (geoplugin_countryCode) {
        const cntryCode = await geoplugin_countryCode();
        return cntryCode;
      }
    
  }

  getCountryCodeList() {
    // this.default_phone_number_country_code = "376";
    return default_const_flag.CountryflagData;

    // this.countryList = country_list.map((key, value) => {
    //   return { id: key.calling_code, name: `${key.short_name}(+${key.calling_code})` };
    //   // return { id: 1, name: `${key.short_name}` };
    // });
    // // this.countryList = [...this.countryList];
    // return [...this.countryList];
    // // return; this.countryList;
  }

  ngAfterViewInit(): void {
    // this.authService.tempUserState$.next({ user_id: 5, previous_step: 2, next_step: 3 });
  
      this.countryList = this.getCountryCodeList();
      // document.getElementsByTagName("body")[0].classList.add("loginpage");

    // this.loadCountryDynmicData();
    this.getGeoLocationDataWithProperty();
  }

  loadCountryDynmicData() {
  
      this.geoplugin_countryCodeData().then(result => {
        const phone_number_country = result;
        const countryData = this.countryList.filter(a => a.key === phone_number_country);
        if (countryData.length === 1) {
          this.countryData = countryData[0];
          this.phone_number_country.setValue(result);
          this.phone_number_country_code.setValue(this.countryData["callingCode"]);
        }
      });
    
  }

  getGeoLocationDataWithProperty() {
    this.token.deleteDataByKey(default_const.GEO_PLUGIN_DATA);
    let data = new Object();
    // to get data from local storge of geo plugin
    const obj_geo_plugin_data = this.token.fetchDataByKey(default_const.GEO_PLUGIN_DATA);

    if (obj_geo_plugin_data) {
      data = obj_geo_plugin_data;

      const phone_number_country = data["geoplugin_countryCode"];
      const countryData = this.countryList.filter(a => a.key === phone_number_country);
      if (countryData.length === 1) {
        this.countryData = countryData[0];
        this.phone_number_country.setValue(phone_number_country);
        this.phone_number_country_code.setValue(this.countryData["callingCode"]);
      }
    } else {
      // this.authService.getGeoLocationData().subscribe({
      //   next: (response: any) => {
      //     data = response._body;
      //     // to store data into local storge of geo plugin
      //     this.token.storeDataByKey(default_const.GEO_PLUGIN_DATA, response._body);

      //     const phone_number_country = data["geoplugin_countryCode"];
      //     const countryData = this.countryList.filter(a => a.key === phone_number_country);

      //     if (countryData.length === 1) {
      //       this.countryData = countryData[0];
      //       this.phone_number_country.setValue(phone_number_country);
      //       this.phone_number_country_code.setValue(this.countryData["callingCode"]);
      //     }
      //   }
      // });
    }
  }

 

  get phone_number_country_code() {
    return this.forgetPasswordResetForm.get("phone_number_country_code");
  }
  get phone_number() {
    return this.forgetPasswordResetForm.get("phone_number");
  }

  get phone_number_country() {
    return this.forgetPasswordResetForm.get("phone_number_country");
  }
}
