<header class="header">
  <nav class="navbar navbar-expand">
    <div class="navbar-brand mr-auto">
      <img src="assets/images/logo-blue.png" alt="logo">
      <div class="toggle" id='menu_btn'>
        <div class="toggle-icon">
          <i class="fa fa-bars"></i>
        </div>
      </div>
    </div>
    <ul class="navbar-nav">
      <li class="nav-item">
        <div class="dropdown">
          <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
            <div class="user-image">
              <img src="{{userImage ? userImage : 'assets/images/user.jpg'}}" alt="user" class="img-fluid">

            </div>
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="javascript:void(0)" [routerLink]="['change-password']">Change Password</a>
            <a class="dropdown-item" href="javascript:void(0)" [routerLink]="['change-email']">Change Email</a>
            <a class="dropdown-item" href="javascript:void(0)" (click)="onLogout()">Logout</a>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</header>
