<div class="sideopen_overlay" id="sideMenuClose"></div>
<div class="sidemenu">
  <div class=" sidebar_wrapper h-100
    mCustomScrollbar" data-mcs-theme="dark">
    <ul id="sidemenu" class="nav flex-column">
      <li class="nav-item" routerLinkActive="active">
        <a href="javascript:void(0)" class="nav-link" [routerLink]="['dashboard']"
           (click)="this.manageDrawer.headerHeading='Dashboard';">
          <i class="fa fa-dashboard"></i>Dashboard</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a href="javascript:void(0)" class="nav-link" [routerLink]="['users']">
          <i class="fa fa-user"></i>Manage User</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a href="javascript:void(0)" class="nav-link" [routerLink]="['property']">
          <i class="fa fa-home"></i>Manage Property</a>
      </li>
      <li class="nav-item" routerLinkActive="active" [routerLink]="['bookings']">
        <a href="javascript:void(0)" class="nav-link">
          <i class="fa fa-bookmark"></i>Manage Booking</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a href="javascript:void(0)" class="nav-link" [routerLink]="['membership-plan']">
          <i class="fa fa-user"></i> Membership Plan
          <!--Feature Property Membership Plan-->
        </a>
      </li>

      <li class="nav-item" routerLinkActive="active">
        <a href="javascript:void(0)" class="nav-link" [routerLink]="['property-type']">
          <i class="fa fa-building"></i>Property Type</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a href="javascript:void(0)" class="nav-link" [routerLink]="['manage-commission']">
          <i class="fa fa-percent"></i>Manage Commission</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a href="javascript:void(0)" class="nav-link" [routerLink]="['settings']">
          <i class="fa fa-gear"></i>Setting</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a href="javascript:void(0)" class="nav-link" [routerLink]="['payment-setting']">
          <i class="fa fa-credit-card"></i>Payment Setting</a>
      </li>

      <li class="nav-item" routerLinkActive="active">
        <a href="javascript:void(0)" class="nav-link" [routerLink]="['reviews/rating']">
          <i class="fa fa-star"></i>Reviews & Rating</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a href="javascript:void(0)" class="nav-link" [routerLink]="['report']">
          <i class="fa fa-flag"></i>Commission Report</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a href="javascript:void(0)" class="nav-link" [routerLink]="['contact-us']">
          <i class="fa fa-phone"></i>Contact Us</a>
      </li>
    </ul>
  </div>
</div>
