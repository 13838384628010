import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NotFoundComponent } from "./shared/component/not-found/not-found.component";
import { AuthGuard } from "@app/auth/services/auth-guard.service";
import { ForgetPasswordComponent } from "@app/auth/forget-password/forget-password.component";
import { ResetPasswordComponent } from "./auth/reset-password/reset-password.component";
import { ForgetPhoneComponent } from "./auth/forget-phone/forget-phone.component";

const AppRoutes: Routes = [
  {
    path: "admin",
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: '',
  //   loadChildren: './admin/admin.module#AdminModule',
  //  // canActivate: [AuthGuard]
  //   // loadChildren: "./admin/admin.module#AdminModule"
  // },
  {
    path: "login",
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: "forgot-password",
    component: ForgetPasswordComponent
  },
  {
    path: "forgot-password-phone",
    component: ForgetPhoneComponent
  },
  {
    path: "reset-password/:token",
    // loadChildren: './auth/reset-password/reset.module#ResetPasswordModule'
    component: ResetPasswordComponent
  },
  {
    path: "**",
    // component: NotFoundComponent,
    redirectTo: "/admin/dashboard"
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(AppRoutes, {
    enableTracing: false,
    useHash: true,
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
