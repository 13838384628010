import { Directive, DoCheck, Input, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

declare var $: any;
@Directive({
  selector: '[appInputFocus]'
})
export class InputFocusDirective implements DoCheck {
  public valLength;
  @Input()
  appInputFocus;
  constructor(public el: ElementRef, public renderer: Renderer2) { }

  ngDoCheck() {
    const valLength = this.el.nativeElement.value.length;
    // console.log("valLength " + valLength);

    if (valLength > 0) {
      // this.renderer.setElementClass(this.el.nativeElement.parentElement, "hasvalue", true);


      this.renderer.addClass(this.el.nativeElement, 'hasvalue');
      this.renderer.addClass(this.el.nativeElement.parentElement, 'hasvalue');
      // this.renderer.nativeElement.parentElement.classList.add(className);

    } else {
      this.renderer.removeClass(this.el.nativeElement, 'hasvalue');
    }

  }
}
