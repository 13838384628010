import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { AuthComponent } from './auth-comp/auth-comp.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../shared/module/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { GlobalDirectivesModule } from '../shared/directives/shared-directives.module';
import { AuthGuard } from './services/auth-guard.service';
import { AuthService } from './services/auth-service.service';
import { AuthRoutes } from './auth-routing.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { ForgetPhoneComponent } from './forget-phone/forget-phone.component';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  imports: [
    GlobalDirectivesModule,
    RouterModule,
    AuthRoutes,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CommonModule,
    HttpClientModule,
    NgSelectModule
  ],
  exports: [RouterModule],
  declarations: [AuthComponent, ForgetPasswordComponent, ResetPasswordComponent,ForgetPhoneComponent],
  providers: [AuthService, AuthGuard]
})
export class AuthModule {}
