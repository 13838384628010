<!-- Confirmation -->
 <div class="modal fade confirmation-modal" id="confirmation-modal" tabindex="-1" role="dialog" aria-labelledby="managehousetype" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-12">
                        <p class="text-center">{{BodyMessage}} </p>
                    </div>
                </div>
                <div class="modal-footer justify-content-center">
                    <button class="btn btn-info btn-md" type="button"  (click)="onConfirmClick()">{{BtnConfirm}} <i class="fa fa-spin fa-spinner" style="display:none"></i></button>
                    <a class="btn btn-primary btn-md" href="javascript:void(0);"  (click)="onCancelClick()"  >{{BtnCancel}}</a>
                </div>
            </div>
        </div>
    </div>
</div>

