<main class="main_content error_page">
  <div id="notfound">
      <div class="notfound">
          <div class="notfound-404">
              <h1>Oops!</h1>
              <h2>404 - The Page can't be found</h2>
          </div>
          <a routerLinkActive="active" [routerLink]="['/admin']" class="btn btn-primary text-uppercase font-md">Go TO Homepage</a>
      </div>
  </div>
</main>
