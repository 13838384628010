import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';

@Component({
    selector: 'app-text-area',
    template: `<textarea class="form-control" rows="5" (blur)="onBlur()" (keyup)="handleChange($event)" [value]="content"></textarea>`,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: TextAreaComponent,
        multi: true
    }]
})

export class TextAreaComponent implements ControlValueAccessor  {

    content: string;
    touchState: boolean;
    _onChange;
    _onTouched;

    constructor() {
        this.content = '';
        this.touchState = false;
    }

    onBlur() {
        this.touchState = true;
        this._onTouched();
    }

    handleChange(event) {
        this.writeValue(event.target.value);
        this._onChange(event.target.value);
    }


    writeValue(value) {
        if (value !== undefined) {
            this.content = value;
        }
    }

    registerOnChange(fn) {
        this._onChange = fn;
    }

    registerOnTouched(fn) {
        this._onTouched = fn;
    }

    checkMessageForEmpty() {
        return (control: FormControl) => {
          if (control.value.length > 0) {
            return null;
          } else {
            return {'messageEmpty': {value: 'Message is empty'}};
          }
        };
      }

}
